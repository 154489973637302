<template>
    <div>
        <div v-show="loading">
            <v-skeleton-loader type="text,text,text"></v-skeleton-loader>
        </div>

        <h4
            class="grey-text font-weight-light mt-3 mb-5"
            v-if="!loading && !payments.length"
        >
            There are no payments.
        </h4>

        <div v-if="!loading && payments.length > 0">
            <v-data-table
                class="compact"
                v-bind="tableSettings"
                :headers="tableHeaders"
                :items="tableItems"
                @click:row="rowClicked"
            />
        </div>
    </div>
</template>
  
<script>
import paymentsAgent from "./paymentsAgent.js";
import { generateHeaders } from "@/util/tableUtil";
import tableSettings from "@/defaults/table";

export default {
    props: {
        id: String,
    },
    data: function () {
        return {
            loading: true,
            payments: [],
        };
    },
    computed: {
        tableSettings: function () {
            return tableSettings;
        },
        tableItems: function () {
            return this.payments.map((paymentItem) => ({
                createdDate: this.$moment(
                    paymentItem["createdDate.utc.value"]
                ).format("DD/MM/YYYY HH:mm"),
                reference: paymentItem.reference,
                supplier:
                    paymentItem["roles.supplier"][0]["roles.supplier.ods.name"],
                status: paymentItem["phase.name"],
                amount: `${paymentItem["currency.symbol"]}${paymentItem[
                    "paymentRequest.totalAmount"
                ].toFixed(2)}`,
                id: paymentItem.id,
            }));
        },
        tableHeaders: function () {
            return generateHeaders(this.tableItems);
        },
    },
    methods: {
        load: async function () {
            try {
                this.loading = true;
                const paymentsResponse =
                    await paymentsAgent.getPaymentsForSharedo(this.id);

                this.payments = paymentsResponse.results.map((result) => ({
                    id: result.id,
                    ...result.data,
                }));

                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        rowClicked: function (item) {
            this.$router.push({
                name: "payment-detail",
                params: { id: item.id },
            });
        },
    },
    mounted: async function () {
        await this.load();
    },
};
</script>